.App {
    height: 100vh;
}

.header {
    margin-top: 10px;
    width: 100%;
    .icon {
        font-size: 24px
    }
    .title {
        font-size: 18px;
    }
}

.form-userinfo {
    margin-top: 10px;
    .btn-confirm {
        width: 120px;
        height: 40px;
        margin-right: 20px;
    }
    .btn-cancel {
        width: 120px;
        height: 40px;
    }
}